import React from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { saveCode } from '../../../utils/local-storage';

const Callback = () => {
  let location = useLocation();
  const query = new URLSearchParams(location.search);
  const code = query.get('code');
  if (code) {
    saveCode(code);
  }
  return (
    <>
      <Link to="/">Back to Home</Link>
    </>
  )
}

export default React.memo(Callback);
