const constant = {
  code: 'code',
  token: 'token',
  idToken: 'idToken',
  refreshToken: 'refreshToken',
  clientId: 'clientId',
}

export const saveCode = (value: string) => {
  localStorage.setItem(constant.code, value);
}

export const saveToken = (value: string) => {
  localStorage.setItem(constant.token, value);
}

export const saveIdToken = (value: string) => {
  localStorage.setItem(constant.idToken, value);
}

export const saveRefreshToken = (value: string) => {
  localStorage.setItem(constant.refreshToken, value);
}

export const saveClientId = (value: string) => {
  localStorage.setItem(constant.clientId, value);
}

export const getCode = () => {
  return localStorage.getItem(constant.code);
}

export const getToken = () => {
  return localStorage.getItem(constant.token);
}

export const getIdToken = () => {
  return localStorage.getItem(constant.idToken);
}

export const getRefreshToken = () => {
  return localStorage.getItem(constant.refreshToken);
}

export const getClientId = () => {
  return localStorage.getItem(constant.clientId);
}

export const removeCode = () => {
  return localStorage.removeItem(constant.code);
}

export const removeToken = () => {
  return localStorage.removeItem(constant.token);
}

export const removeIdToken = () => {
  return localStorage.removeItem(constant.idToken);
}

export const removeRefreshToken = () => {
  return localStorage.removeItem(constant.refreshToken);
}

export const removeClientId = () => {
  return localStorage.removeItem(constant.clientId);
}
