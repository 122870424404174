import React from 'react';
import { Button } from 'antd';
import { unlink } from 'src/services/token-request';
import { removeCode, removeToken, removeIdToken, removeClientId, removeRefreshToken } from 'src/utils/local-storage';

const Unlink = () => {
  const [loading, setLoading] = React.useState(false);

  const doUnlink = async () => {
    try {
      setLoading(true);
      await unlink();
      // REMOVE LOCALHOST DATA
      removeClientId();
      removeCode();
      removeToken();
      removeIdToken();
      removeRefreshToken();
      setLoading(false);
      window.location.href = '/';
    } catch (error) {
      setLoading(false);
    }
  }
  return (
    <div className="unlink">
      <Button type="primary" loading={loading} onClick={() => doUnlink()}>Unlink</Button>
    </div>
  )
}

export default React.memo(Unlink)
