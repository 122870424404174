import React from 'react';
import { Input, Button } from 'antd';

import './index.css';
import {requestB2BToken, requestUrl} from "../../../services/registration";
import SignatureBuilder from "../../../utils/signature-builder";

const Login = () => {
  const initialRedirectUrl = `${process.env.REACT_APP_URL}/callback`;

  const [formCreateToken, setFormCreateToken ] = React.useState({
    clientId: null,
    fullName: null,
    externalUid: null,
    grantType: "openid+offline+linkagepayment.pay+linkagepayment.check+linkagepayment.refund+linkagepayment.unlink",
    redirectUrl: initialRedirectUrl,
    mobileNumber: null,
  })

  const handleChangeInput = (e) =>{
    const{
      value,
      id
    } = e.target
    setFormCreateToken(prevState => ({...prevState, [id]: value}))
  }

  const saveClientId = () => {

    // saveToLocal(partnerClientId);
  }
  const openNewWindow = () => {
    saveClientId();
    // window.open(linkBuilder, '_blank', `targetwindow,toolbar=0,menubar=0,scrollbars=1,resizable=1,width=500,height=800`)
  }
  const handleSubmit = async (type: string = 'current-tab') => {
    const timestamp = new Date().toISOString();
    const signatureX = SignatureBuilder(formCreateToken.clientId, timestamp)
    const getAccessToken = await requestB2BToken({
      payload : {
        grantType: 'client_credentials',
        additionalInfo: {
          scopes: formCreateToken.grantType
        }
      },
      signatureX,
      timestamp,
      clientKey: formCreateToken.clientId
    })
    const getUrl =  await requestUrl({
      token: getAccessToken.accessToken,
      mobile_number: formCreateToken.mobileNumber,
      external_uid: formCreateToken.externalUid,
      full_name: formCreateToken.fullName,
      redirect_uri: formCreateToken.redirectUrl})
    switch (type){
      case "current-tab":
        window.location.href = getUrl.url;
        break;
      case "new-tab":
        window.open(getUrl.url) ;
        break;
      case "new-window":
        window.open(getUrl.url, '_blank', `location=yes,height=844,width=390,scrollbars=yes,status=yes`)
        break;
      default:
        return
    }
  }

  return (
      <div className="login">
        <div className="login_item">
          <div className="login_item-input">
            <label htmlFor="partner_client_id">Partner Client Id</label>
            <Input id={"clientId"} placeholder="contoh: alfagift.test atau qa.test" onChange={handleChangeInput} />
          </div>
          <div className="login_item-input">
            <label htmlFor="username">Partner Username</label>
            <Input id={"fullName"} placeholder="contoh: Yoga atau USER-001" onChange={handleChangeInput} />
          </div>
          <div className="login_item-input">
            <label htmlFor="username">External Uid</label>
            <Input id={"externalUid"} placeholder="contoh: sUbX5NBW74" onChange={handleChangeInput} />
          </div>
          <div className="login_item-input">
            <label htmlFor="partner_client_id">Grant Type</label>
            <Input id={"grantType"} placeholder="contoh: client_credential" value={formCreateToken.grantType} onChange={handleChangeInput} />
          </div>
          <div className="login_item-input">
            <label htmlFor="redirect_url">Redirect Url</label>
            <Input id={"redirectUrl"} placeholder={`contoh: ${initialRedirectUrl}`} value={formCreateToken.redirectUrl} onChange={handleChangeInput} />
          </div>
          <div className="login_item-input">
            <label htmlFor="login-hint">No Handphone</label>
            <Input id={"mobileNumber"} placeholder="081388272" onChange={handleChangeInput} />
          </div>
        </div>
        {
            (formCreateToken.clientId
                && formCreateToken.externalUid
                && formCreateToken.grantType
                && formCreateToken.redirectUrl
                && formCreateToken.mobileNumber
            ) && (
                <div className="login_item">
                  <div className="login_item-title">1. Redirect Current Tab</div>
                  <div className="login_item-content">
                    <Button onClick={()=>handleSubmit('current-tab')} type="primary">Click To Login</Button>
                  </div>
                  <div className="login_item-title">2. Redirect With New Tab</div>
                  <div className="login_item-content">
                    <Button onClick={()=>handleSubmit("new-tab")} type="primary">Click To Login</Button>
                  </div>
                  <div className="login_item-title">3. Opening New Window</div>
                  <div className="login_item-content">
                    <Button type="primary" onClick={()=>handleSubmit('new-window')}>Click To Login</Button>
                  </div>
                </div>
            )
        }
      </div>
  )
}

export default React.memo(Login);
