import CryptoJS from "crypto-js";
const rs = require('jsrsasign');
export default function SignatureBuilder(clientID, timestamp){
    const privateKey = `-----BEGIN RSA PRIVATE KEY-----
MIIEowIBAAKCAQEAvTh4lhSwEwYORtwYpCMyz6utKIQk3VHBHmeWZdcrPiElg/f/
mk0BG3ZaJ6VCkc6EjzqtRdqozd16mtT88K5jiHHK1ykI9JOI0qQ+o0KuW2Rokncj
Kz5WGZA0kf37BY9oVreJttTRwBr24DqLoB2udPxJYtQY8wNWMMZ0RXrnwH2FJdVN
D+UePRWO5hwCi8ZW4/v8yFSxDnbXr71NL0Qks4Wytj80STMXsFSNiYbzmtCIqgHU
00O1msf3KMMnouBnAjEj/cvc+um6BOKjsqmZtjS0Tp1PNz7QOFdMnxCOIK7KJCH2
wLXGLw65kcT5jXThUMZzfX4O0GB5Zge+YJj9DwIDAQABAoIBABIY4I+aEuz+20az
rjeklit+g+UoHk58jzfVOHEtDxm2GdxxT6T2UHDL9PttttwzIEKCnifcLeDimeNN
E5QBWcum9nt1lUGvCpr60nLS4dIj3EyF0HtGTv3K6Pw9ilCR0qzYiPdPi3AQJCR1
9PyvWgweRPaKyogTfZ7UnDI4+wlRzdTUMTV7+r8Jrn6gFx2mB5YYxu9gZmqGmaMU
R4XvKG/roVt+BBsAaVaQmcvunJqZI5yqeXEr5O6ReZ20+6ckbEmzw2/RvUTretkd
a1o2TaYNHhy9y+dxpVMXxqNzAUpeVlfUMZ97tZehKySEahP5ia1AWUAnen3Q5/iO
+U1AekECgYEA5J1YnjZE3ULRXjIpMku4yo1/aV/t8OgLIuUznhdmWh3JuugLwfhT
2R7FVYi6LfMVP0dhDAJIYBaNhRALetzTty5zZgf4XHYrIxbF7UvummA6gae4iZ3d
4aExA5ArnJSOmFX9s8Fwa/lDl4Rb9W2UuOD7qRvmiFiYt+ROJpicUakCgYEA0+MT
ECZccjEkG3PNVKv+TezvYeaMvhH1QbXgAAi6FHcucBTNW2cRtjJmMsDn3pnv9vkr
ZifW+ujD/sqFTTKGF/hVdz0B2sDVeITkKuYXTo46EZNLA8rQ+No/3RpDV0U9FPa3
j8tD1o+yg62xnArRD32YiK+nGcY45eDZX7x6e/cCgYBs9iG6ppsD6kjn4HiKJFUb
FX3Yt4QtAivsodmMTBLYeMIhd1/DP5yXK3daYSR7qhRCaL70flIImjcxaIhQMtgl
wtn1k27ogtnki4Iiq8I5igvYqBvoW3nZhH5b+GcQ5i1gpfuZpWTz0YZJvSgnPedY
hsLrwwSJj9CaNRKairpWoQKBgQDPi4MmtIylBeF6Smn74CTOq6CnYq7Fls2HOeFZ
5zJtzXfdLWMhfsnoj2FvoWj9sAdPAS4zeB6p0sn4juMXhL+4RvVgQI/KeEwhKvpb
0tlNhx72DDLUWhtrRkx8BLelihqUqD2RSeb/Ce4v8Tkhwa6Z38pDSNsC8hjVgvMp
RXMsbQKBgGGid9szJaihois2HAmUmlz5jtGHs262RzfLUOhZebl8OStTWizunZQv
qJ1+54EWc/pzNbU08Z/tbEpF6gNx2+dNKPJ/mmG6rzUUVC+RsBqbgpuNcalPObw8
JU0x8lxB6zqO6vl5UMAxCGJyyvbdsvr02DsAxw2/fSmDhRaGZYGS
-----END RSA PRIVATE KEY-----`;

    const data = `${clientID}|${timestamp}`;
    const sig = new rs.KJUR.crypto.Signature({"alg": "SHA256withRSA"});
    sig.init(privateKey);
    const hash = sig.signString(data);
    return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Hex.parse(hash));

}