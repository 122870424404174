import axios from "axios";
import {notification} from "antd";

export const requestB2BToken = async ({ payload, signatureX, clientKey, timestamp }) => {
    try {
        const response = await axios({
            method: 'POST',
            baseURL: process.env.REACT_APP_API_URL,
            url: '/v1.0/access-token/b2b',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'X-CLIENT-KEY': clientKey,
                'X-TIMESTAMP': timestamp,
                'X-SIGNATURE': signatureX,
            },
            data: payload
        });
        return JSON.parse(response.request.response)
    } catch (error) {
        notification.error({
            message: 'Error',
        });
        throw error
    }
}

export const requestUrl = async ({token, mobile_number, external_uid, full_name, redirect_uri}) => {
    try {
        const response = await axios({
            method: 'POST',
            baseURL: process.env.REACT_APP_API_URL,
            url: '/r/user/check-mobile-number',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            data: {
                mobile_number,
                external_uid,
                full_name,
                redirect_uri
            },
        });
        return JSON.parse(response.request.response)
    } catch (error) {
        notification.error({
            message: 'Error',
        });
        throw error
    }
}