import React from 'react';
import {
  Router,
  Switch,
  Route,
} from "react-router-dom";
import { createBrowserHistory } from 'history';
import callback from './components/pages/callback';
import unlink from './components/pages/unlink';
import home from './components/pages/home';
import login from './components/pages/login';
import payment from './components/pages/payment';
import register from './components/pages/register';

const history = createBrowserHistory();

function App() {
  return (
    <div className="App" style={{ padding: '40px' }}>
      <Router history={history}>
        <Switch>
          <Route path="/" component={home} exact />
          <Route path="/login" component={login} exact />
          <Route path="/payment" component={payment} exact />
          <Route path="/callback" component={callback} exact />
          <Route path="/unlink" component={unlink} exact />
          <Route path="/register" component={register} exact />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
